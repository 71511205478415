import React from 'react'
import SEO from 'components/elements/SEO/SEO'
import CustomImage from 'components/foundations/Image/CustomImage'

export default function AboutPage() {
  return (
    <>
      <SEO title="Chi siamo" keywords={['SEO0', 'SEO1', 'SEO2']} />
      <div className="about">
        <div className="closer">
          <CustomImage filename="about.jpg" />
          <div className="about-content">
            <h3>Chi siamo</h3>
            <p>
              I NOSTRI PUNTI DI FORZA: CREATIVITA’ E RIGORE, IL MIX MIGLIORE <br />
              Il nome ING GROUP riflette la doppia anima della società ed assume due significati diversi: la tecnica ed
              il rigore dell’ingegneria e la creatività del gruppo. <br />
              Oggi il nostro core business comprende tutte le aree della consulenza su sicurezza del lavoro e sistemi di
              gestione e tutte le attività tipiche dell’ingegnere, dalla progettazione alla direzione lavori unitamente
              a mdicina del lavoro e formazione. <br />
              ING GROUP è tra le poche realtà presenti sul mercato in grado di soddisfare globalmente le esigenze delle
              aziende nel settore della progettazione e della consulenza tecnica e della medicina del lavoro: la
              nostra&nbsp;
              <b>mission</b> è la soddisfazione del Cliente, essere il riferimento tecnico ed il supporto allo sviluppo
              commerciale dei nostri clienti/partner. Nel mercato attuale la crescita è soprattutto <b>competitività</b>
              , ed il team ING GROUP sa percepire le esigenze del cliente ed affiancarlo nel raggiungimento degli
              obiettivi prefissati insieme. <br /> <br />
              <b>PERCHÉ SCEGLIERE NOI:</b> <br />
              1)REFERENTE TECNICO UNICO già SENIOR SECURITY MANAGER Certificato ICIM al nr. ICIM-PDS-016783-00, DATA
              PROTECTION OFFICER, VALUTATORE per ENTI di CERTIFICAZIONE e RSPP <br />
              2) REFERENTE UNICO RAPPORTI CON CLIENTE con cui interfacciarsi H24: un unico referente ed il meglio della
              specializzazione! <br />
              3) 50 Centri medici convenzionati in Italia <br />
              4) 3 Enti di formazione accreditati in convenzione <br />
              5) RISK ASSESSMENT PRELIMINARE <br />
              6) POLIZZA ASSICURATIVA PROFESSIONALE MPS-AXA a tutela delle prestazioni <br />
              7) ASSISTENZA IN REMOTO tutti i giorni lavorativi da parte di professionisti dello specifico settore
              normativo <br />
              8) CONOSCENZA “INEGUAGLIABILE” IN ITALIA DEL SETTORE SAFETY &amp; SECURITY <br />
              9) RISERVATEZZA DELLE INFORMAZIONI con software specialistico in conformità a GDPR e ISO 27001 <br />
              10) TRE SEDI (MILANO – NAPOLI – VICENZA) a disposizione con ASSENZA TOTALE DI COSTI DI TRASFERTA <br />
              <br />
              <b>ING GROUP E’:</b> <br />
              BUSINESS UNIT INGEGNERIA E CONSULENZA TECNICA <br />
              BUSINESS UNIT MEDICINA DEL LAVORO E FORMAZIONE <br />
              OMNE Srl: PRODOTTI E SERVIZI PER SAFETY, SECURITY, ANTINCENDIO <br />
              Tutto questo ed altro ancora è ING GROUP.
            </p>
          </div>
        </div>
        <div className="about-bottom">
          <div className="about-item closer">
            <div>
              <CustomImage filename="about-ing-group.jpg" />
              <h5>ING GROUP</h5>
              <p>
                Noi di IngGroup offriamo ai nostri clienti consulenza e servizi di ingegneria professionali, nonché
                supporto completo e tempestivo per ogni esigenza.
              </p>
            </div>
            <div>
              <CustomImage filename="about-omne.jpg" />
              <h5>OMNE</h5>
              <p>
                Omne fornisce attraverso le aziende aderenti e partners di prestigio servizi e prodotti per
                safety,security ed antincendio.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
